<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ light: lightMode }"
        class="reputation-container"
    >
        <div class="header-container">
            <text-wipe
                id="reputation-header"
                :color="shaderColor"
                :header="header"
            />
        </div>
        <div
            v-observer
            class="center-container"
            v-if="this.header.title !== null"
        >
            <div class="images">
                <image-scale-component
                    v-for="(item, index) in upImages"
                    :key="index"
                    :item="item"
                    v-observer
                    data-scroll
                    :index="index"
                />
            </div>
            <span class="description" v-html="mainDescription"></span>
        </div>
        <div class="video-container">
            <image-scale-component
                v-for="(item, index) in downImages"
                :key="index"
                :item="item"
                v-observer
                data-scroll
                :index="index"
            />
            <div class="video">
                <mouse-changer :type="mouseTypes.VideoScale">
                    <div
                        class="video-scale-container"
                        @click="showVideo"
                        data-scroll
                        data-scroll-id="inside-scale"
                    >
                        <div class="video-bg"></div>
                        <video
                            muted
                            autoplay
                            loop
                            playsinline
                            :poster="coverImage"
                        >
                            <source :src="mainSlide" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </mouse-changer>
                <div class="mobile-play-video">
                    <svg
                        class="icon"
                        width="40.759"
                        height="22.708"
                        viewBox="0 0 40.759 22.708"
                    >
                        <g
                            id="Group_55"
                            data-name="Group 55"
                            transform="translate(1.414 1.414)"
                        >
                            <line
                                id="Line_3"
                                data-name="Line 3"
                                x2="19.005"
                                y2="19.881"
                                transform="translate(18.926 0)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                            <line
                                id="Line_4"
                                data-name="Line 4"
                                x1="18.927"
                                y2="19.881"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                            <line
                                id="Line_5"
                                data-name="Line 5"
                                x2="38"
                                transform="translate(0.027 19.881)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="descriptions">
                <div
                    v-observer
                    class="description"
                    :class="{ active: moveIndex % 3 === 1 }"
                    v-html="videoDescriptions[0]"
                ></div>
                <div
                    v-observer
                    class="description"
                    :class="{ active: moveIndex % 3 === 2 }"
                    v-html="videoDescriptions[1]"
                ></div>
                <div
                    v-observer
                    class="description"
                    :class="{ active: moveIndex % 3 === 0 }"
                    v-html="videoDescriptions[2]"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import textWipe from "@/components/custom/textWipe";
import ImageScale from "@/mixins/ImageScale";
import ImageScaleComponent from "@/components/custom/ImageScale";
import MouseChanger from "@/components/custom/MouseChanger";
// import debounce from "lodash/debounce";
import { mapActions, mapGetters } from "vuex";

export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        data: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            moveIndex: 0
        };
    },
    components: {
        textWipe,
        ImageScaleComponent,
        MouseChanger
    },
    mixins: [ImageScale],
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        fullVideo() {
            if (window.innerWidth < 1024) {
                return this.data.list?.[0]?.mobile_video?.videoFile?.url;
            }
            return this.data.list?.[0]?.video?.videoFile?.url;
        },
        mainSlide() {
            if (window.innerWidth < 1024) {
                return this.data.list?.[0]?.mobile_video_intro?.videoFile?.url;
            } else {
                return this.data.list?.[0]?.video_intro?.videoFile?.url;
            }
        },
        coverImage() {
            return this.data?.list?.[0]?.cover_image?.[0]?.devices?.[
                this.device?.type
            ];
        },
        mainDescription() {
            return this.config.header?.teaser;
        },
        images() {
            return this.data?.list?.[0]?.images?.map(item => ({
                image: item?.devices?.[this.device?.type],
                description: item?.title?.description,
                title: item?.title?.title,
                teaser: item?.title?.teaser
            }));
        },
        videoDescriptions() {
            let mainItem = this.data?.list?.[0]?.video_options?.tabs || {};
            return [
                mainItem?.[0]?.content,
                mainItem?.[1]?.content,
                mainItem?.[2]?.content
            ];
        },
        upImages() {
            return this.images?.slice(0, 2);
        },
        downImages() {
            return this.images.slice(2, this.images?.length);
        },
        header() {
            return this.config.header || {};
        },
        shaderColor() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        }
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent"
        }),
        // mouseMoveHandler() {
        //     this.debounceEvent = debounce(this.mouseMove, 700);

        //     window.addEventListener("mousemove", this.debounceEvent, false);
        // },
        mouseMove() {
            this.moveIndex++;
        },
        showVideo() {
            this.setContent({
                path: this.fullVideo
            });
            // setTimeout(() => {
            //   this.gsap.to('.video-player', {
            //     y: 0,
            //     onComplete: () => {
            //       this.gsap.to('.video-player video', {
            //         opacity: 1
            //       })
            //     }
            //   })
            // }, 200)
        },
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(
                    ".reputation-container .image-speed"
                );
                new this.simpleParallax(images[0], {
                    customContainer: document.querySelector("body"),
                    overflow: true
                });
            }
        }
    },
    created() {
        setInterval(() => {
            this.mouseMove();
        }, 7000);
        // this.mouseMoveHandler();
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
    // beforeDestroy() {
    //     if (this.debounceEvent)
    //         window.removeEventListener("mousemove", this.debounceEvent);
    // }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.video {
    position: relative;

    .video-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
        cursor: pointer;
        will-change: transform;
    }
}

.reputation-container /deep/ {
    @include center-children;
    flex-direction: column;

    .header-container {
        padding: 0 75px;
        @media only screen and (max-width: 767px) {
            padding: 0 16px;
        }
    }

    .center-container {
        @include center-children;
        flex-direction: column;
        margin-top: 318px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin-top: 233px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin-top: 144px;
        }

        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            margin-top: 89px;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 55px;
        }
        @media only screen and (max-width: 1023px) {
            width: 100%;
        }

        &.observed {
            .images {
                .image-speed {
                    &:first-child {
                        .image-container {
                            .image {
                                transform: scale(1);
                            }
                        }
                    }

                    &:last-child {
                        .image-container {
                            clip-path: inset(30px);
                            transform: scale(1);
                            @media only screen and (max-width: 1023px) {
                                clip-path: inset(15px);
                            }
                        }
                    }
                }

                .image-container {
                    transform: translateY(0);
                }
            }
        }

        .images {
            display: flex;

            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                grid-gap: unset;
                justify-content: space-between;
                width: calc(100% - 64px);
            }
            @media only screen and (max-width: 767px) {
                grid-gap: 0;
                flex-direction: column;
                width: 100%;
            }

            .image-speed {
                &:first-child {
                    @media only screen and (max-width: 768px) {
                        align-self: flex-start;
                    }

                    .image-container {
                        width: 330px;
                        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                            width: 220px;
                        }
                        @media only screen and (max-width: 1023px) and (min-width: 768px) {
                            width: 245px;
                        }
                        @media only screen and (max-width: 767px) {
                            width: 230px;
                        }

                        .image {
                            transform: scale(1.2);
                        }
                    }
                }

                &:last-child {
                    margin-left: 90px;
                    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                        margin-left: 35px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        margin-left: 80px;
                    }
                    @media only screen and (max-width: 1023px) {
                        margin-left: unset;
                    }
                    @media only screen and (max-width: 767px) {
                        align-self: flex-end;
                    }

                    .image-container {
                        width: 510px;
                        margin-top: 146px;
                        clip-path: inset(60% 30px 0 30px);

                        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                            width: 345px;
                        }

                        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                            width: 300px;
                        }
                        @media only screen and (max-width: 1023px) and (min-width: 768px) {
                            width: 360px;
                            clip-path: inset(60% 15px 0 15px);
                        }
                        @media only screen and (max-width: 767px) {
                            width: 315px;
                            margin-top: -30%;
                            clip-path: inset(60% 15px 0 15px);
                        }
                    }
                }
            }
        }

        .description {
            font-size: 55px;
            font-style: normal;
            color: white;
            max-width: 895px;
            margin-top: 81px;
            line-height: 60px;

            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                max-width: 546px;
                font-size: 34px;
                line-height: 60px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                max-width: 445px;
                font-size: 21px;
                line-height: 40px;
            }

            @media only screen and (max-width: 767px) {
                max-width: 381px;
                margin-top: 55px;
                font-size: 21px;
                line-height: 40px;
            }
        }
    }

    .video-container {
        width: 100%;
        // margin-top: 295px;
        position: relative;
        // @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        //     margin-top: 244px;
        // }
        // @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        //     margin-top: 224px;
        // }
        // @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        //     margin-top: 204px;
        // }
        // @media only screen and (max-width: 1023px) and (min-width: 768px) {
        //     margin-top: 144px;
        // }
        // @media only screen and (max-width: 767px) {
        //     margin-top: 89px;
        // }

        .descriptions {
            position: absolute;
            top: 50%;
            left: 195px;

            .description {
                position: absolute;
                left: 0;
                top: 0;
                transform: translateY(-50%);
                color: white;
                max-width: 700px;
                letter-spacing: 5.5px;
                line-height: 60px;
                width: max-content;
                text-align: left;
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    max-width: 550px;
                }

                p {
                    opacity: 0;
                    transition: all 0.6s $ease-out;
                }

                &.active {
                    p {
                        opacity: 1;

                        @for $i from 1 through 3 {
                            &:nth-child(#{$i}) {
                                transition: all 0.6s 0.4 + $i/10 + S $ease-out;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }

        .image-speed {
            position: absolute;
            z-index: 2;

            &.observed {
                .image-container {
                    clip-path: inset(30px) !important;
                    @media only screen and (max-width: 1023px) {
                        clip-path: inset(15px) !important;
                    }
                }

                &:first-child {
                    img {
                        transform: scale(1) translateY(0);
                    }
                }

                &:nth-child(2) {
                    img {
                        transform: scale(1) translateY(0);
                    }
                }

                &:nth-child(3) {
                    img {
                        transform: scale(1) translateY(0);
                    }
                }
            }

            @media only screen and (max-width: 1023px) {
                img {
                    display: none !important;
                }
            }

            .image-container {
                transition: all 0.6s 0.2s $ease-out, opacity 0.1ms;
            }

            &:first-child {
                .image-container {
                    left: 165px;
                    top: -200px;
                    width: 360px;
                    clip-path: inset(30px 30px 45% 30px);
                    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                        width: 250px;
                        left: 135px;
                    }
                    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                        left: 98px;
                        width: 250px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        left: 17px;
                        width: 220px;
                    }
                }

                img {
                    transform: scale(1.2);
                }
            }

            &:nth-child(2) {
                right: 30px;

                .image-container {
                    top: -400px;
                    width: 288px;
                    clip-path: inset(45% 30px 30px 30px);
                    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                        width: 320px;
                    }
                    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                        width: 283px;
                        top: -300px;
                        right: 20px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        width: 187px;
                        top: -250px;
                        right: -20px;
                    }
                    @media only screen and (max-width: 1023px) {
                        width: 187px;
                        top: -250px;
                        right: 10px;
                    }
                }
            }

            &:nth-child(3) {
                right: 75px;
                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    right: 135px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    right: 98px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    right: 17px;
                }

                .image-container {
                    top: -199px;
                    width: 480px;
                    clip-path: inset(30px 30px 60% 30px);
                    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                        width: 315px;
                        top: -159px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        width: 270px;
                        top: -129px;
                    }
                }
            }
        }

        .video {
            position: relative;
            overflow: hidden;
            height: 900px;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                height: 675px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                height: 640px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                height: 480px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                height: 432px;
            }
            @media only screen and (max-width: 767px) {
                height: 233px;
            }

            video {
                width: 110%;
                left: -5%;
                position: relative;
            }
        }

        .mobile-play-video {
            @include center-children;
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            @include circle(80);
            border: 1px solid #e5b676;
            pointer-events: none;
            @media only screen and (max-width: 1023px) {
                display: flex;
            }

            .icon {
                transform: rotate(90deg);
            }
        }
    }
}

.reputation-container.light /deep/ {
    .header-container {
        .header {
            color: #0a141c;
        }
    }

    .center-container {
        .description {
            color: #0a141c;
        }
    }
}
</style>
